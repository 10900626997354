
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$registration-form-margin-y: $spacer !default;
$registration-form-padding-y: $spacer !default;
$registration-form-border-y: map-get($borders, 'base') !default;

$registration-action-gap: $spacer !default;

.registration {
  .registration-form {
    margin-top: $registration-form-margin-y;
    margin-bottom: $registration-form-margin-y;
    padding-top: $registration-form-padding-y;
    padding-bottom: $registration-form-padding-y;
    border-top: $registration-form-border-y;
    border-bottom: $registration-form-border-y;

    &.no-border-bottom {
      border-bottom: none;
    }
  }

  .registration-termsandpolicies {}

  .registration-action {
    margin-top: $registration-action-gap;
  }
}
