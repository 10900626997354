
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$headersearch-form-gap: $spacer * 0.5 !default;
$headersearch-form-height: $spacer * 2 !default;
$headersearch-form-border: map-get($borders, 'bold') !default;
$headersearch-form-focus-border: set-nth($headersearch-form-border, 3, $primary) !default;

$headersearch-form-submit-padding-y: 0 !default;
$headersearch-form-submit-padding-x: $headersearch-form-height * 0.33 !default;
$headersearch-form-focus-submit-color: $primary !default;

$headersearch-form-input-padding-y: $headersearch-form-submit-padding-y !default;
$headersearch-form-input-padding-x: $headersearch-form-submit-padding-x !default;
$headersearch-form-input-bg: $input-bg !default;
$headersearch-form-input-placeholder-color: $body-color !default;

// desktop
$headersearch-desktop-form-gap: $spacer * 0.5 !default;
$headersearch-desktop-form-width: 300px !default;

.account-orders {
  .search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: $headersearch-form-gap 0;
    height: $headersearch-form-height;
    background-color: $headersearch-form-input-bg;
    border: $headersearch-form-border;

    .search-input {
      flex: 1 1 100%;
      margin: 0;
      padding: $headersearch-form-input-padding-y $headersearch-form-input-padding-x;
      height: 100%;
      background: none;
      border: 0 none;
      outline: 0 none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      box-shadow: none;

      &::placeholder {
        color: $headersearch-form-input-placeholder-color;
      }

      &:last-child {
        padding-left: 0;
      }
    }

    .search-submit {
      flex: 0 0 auto;
      padding: $headersearch-form-submit-padding-y $headersearch-form-submit-padding-x;
      background: none;
      border: 0 none;

      .clear {
        cursor: pointer;
      }

      &:last-child {
        padding-left: 0;
      }
    }

    &.focus {
      border: $headersearch-form-focus-border;

      .search-submit {
        color: $headersearch-form-focus-submit-color;
      }
    }
  }
}
