
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$entityeditform-action-gap: $spacer !default;

$entityeditform-inline-padding-y: $spacer * 0.5 !default;
$entityeditform-inline-border-y: map-get($borders, 'base') !default;

// tablet

$entityeditform-tablet: $tablet-breakpoint !default;

.entity-edit-form {
  .form-controls {}

  .form-action {
    display: flex;
    justify-content: flex-end;
    margin-top: $entityeditform-action-gap;
  }

  &:not(.form-mode-write) {
    .form-controls {
      .controls-col {
        .form-group {
          label {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.inline {
    padding-top: $entityeditform-inline-padding-y;
    padding-bottom: $entityeditform-inline-padding-y;
    border-top: $entityeditform-inline-border-y;
    border-bottom: $entityeditform-inline-border-y;

    &:not(.form-mode-write) {
      .form-controls {
        justify-content: space-between;
        align-items: center;

        .controls-col {
          display: flex;

          .form-group {
            margin-bottom: 0;
          }
        }
      }

      .form-action {
        margin-top: 0;
      }

      + .entity-edit-form {
        border-top: 0 none;
      }
    }
  }

  @include media-breakpoint-up($entityeditform-tablet) {
    [class*="col-md-12"] {
      .form-group {
        input:not([type="checkbox"]):not([type="radio"]),
        select {
          max-width: calc(50% - ($form-grid-gutter-width * 0.5));
        }
      }
    }
  }
}
