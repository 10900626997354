
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$ordersummary-gap: $spacer * 1.5 !default;
$ordersummary-header-padding-y: $component-padding-x !default;
$ordersummary-header-padding-x: $component-padding-x !default;
$ordersummary-header-bg: $beige !default;
$ordersummary-header-border-radius: $border-radius !default;

$ordersummary-header-descriptionlist-grid-gutter: $spacer * 0.75 !default;
$ordersummary-header-descriptionlist-grid-columns: auto auto !default;
$ordersummary-header-descriptionlist-gap: $spacer !default;
$ordersummary-header-descriptionlist-line-height: 1.2 !default;
$ordersummary-header-descriptionlist-item-title-font-size: $font-size-sm !default;
$ordersummary-header-descriptionlist-item-title-font-weight: inherit !default;
$ordersummary-header-descriptionlist-item-title-color: $dark !default;
$ordersummary-header-descriptionlist-item-data-font-size: inherit !default;
$ordersummary-header-descriptionlist-item-data-font-weight: inherit !default;
$ordersummary-header-descriptionlist-item-data-color: inherit !default;

// tablet
$ordersummary-tablet: $tablet-breakpoint !default;

$ordersummary-tablet-items-border: map-get($borders, 'bold') !default;

$ordersummary-tablet-items-item-grid-gutter: $spacer * 0.75 !default;
$ordersummary-tablet-items-item-grid-columns: min-content auto max-content !default;
$ordersummary-tablet-items-item-padding-y: $ordersummary-header-padding-y * 0.5 !default;
$ordersummary-tablet-items-item-padding-x: $ordersummary-header-padding-x !default;
$ordersummary-tablet-items-item-border: map-get($borders, 'base') !default;
$ordersummary-tablet-items-item-img-width: 75px !default;
$ordersummary-tablet-items-item-img-fallback-color: $imgfallback !default;

.order-summary {
  margin-bottom: $ordersummary-gap;

  .order-header {
    padding: $ordersummary-header-padding-y $ordersummary-header-padding-x;
    background-color: $ordersummary-header-bg;
    border-radius: $ordersummary-header-border-radius;

    dl {
      display: grid;
      grid-gap: $ordersummary-header-descriptionlist-grid-gutter;
      grid-template-columns: $ordersummary-header-descriptionlist-grid-columns;
      margin: $ordersummary-header-descriptionlist-gap 0;
      line-height: $ordersummary-header-descriptionlist-line-height;

      &:last-child {
        margin-bottom: 0;
      }

      dt {
        margin: 0;
        font-size: $ordersummary-header-descriptionlist-item-title-font-size;
        font-weight: $ordersummary-header-descriptionlist-item-title-font-weight;
        color: $ordersummary-header-descriptionlist-item-title-color;
      }

      dd {
        margin: 0;
        font-size: $ordersummary-header-descriptionlist-item-data-font-size;
        font-weight: $ordersummary-header-descriptionlist-item-data-font-weight;
        color: $ordersummary-header-descriptionlist-item-data-color;
      }
    }
  }

  .order-items {
    display: none;
  }

  @include media-breakpoint-up($ordersummary-tablet) {
    .order-header {
      dl {
        grid-template-columns: auto;
        grid-auto-flow: column;
      }
    }

    .order-items {
      display: block;
      border-top: $ordersummary-tablet-items-border;

      .item {
        display: grid;
        grid-gap: $ordersummary-tablet-items-item-grid-gutter;
        grid-template-columns: $ordersummary-tablet-items-item-grid-columns;
        align-items: center;
        padding: $ordersummary-tablet-items-item-padding-y $ordersummary-tablet-items-item-padding-x;
        border-top: $ordersummary-tablet-items-item-border;
        border-bottom: $ordersummary-tablet-items-item-border;

        .item-image {
          width: $ordersummary-tablet-items-item-img-width;
          max-width: unset;

          .bi {
            width: 100%;
            height: 100%;
            color: $ordersummary-tablet-items-item-img-fallback-color;
          }
        }
      }
    }

    &.show-items {
      .order-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-of-type {
        margin-bottom: 0;

        .order-items {
          .item {
            border-bottom: none;
          }
        }

        & + hr {
          margin-top: 0;
        }
      }
    }
  }
}
