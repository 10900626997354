
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.account-company {
  .actions {
    .btn-link {
      border: none;
      background: none;

      &.disabled {
        pointer-events: auto;
        cursor: unset;
      }
    }
  }
}
