
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$account-subentity-addform-gap: $spacer * 2 !default;
$account-subentity-actions-gap: $spacer * 0.25 !default;

.account {
  .subentity-type {
    .subentity-addform {
      margin-top: $account-subentity-addform-gap;
    }

    .subentity-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: $account-subentity-actions-gap;
    }
  }
}
