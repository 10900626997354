
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$userline-padding-y: $spacer * 0.5 !default;
$userline-padding-x: 0 !default;
$userline-border: map-get($borders, 'base') !default;

.user-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $userline-padding-y $userline-padding-x;
  border-top: $userline-border;
  border-bottom: $userline-border;

  + .user-line {
    margin-top: nth($userline-border, 1) * -1;
  }

  &.inline {
    padding: 0;
    border: 0 none;

    + .user-line {
      margin-top: 0;
    }
  }
}
